'use client';

import { useEffect } from 'react';

import PageError from '@/components/error-pages/PageError';
import { captureException } from '@/utils/sentry';

export default function Error({ error }: { error: Error }) {
  useEffect(() => {
    captureException(error.message);
  }, [error]);

  return <PageError statusCode={500} />;
}
